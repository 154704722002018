const es = {
  aProblemHasOccurred: "Ha ocurrido un problema",
  acceptThe: "Aceptar los",
  actions: "Acciones",
  active: "Activo",
  addCertificates: "Agregar certificados",
  address: "Dirección",
  installmentsNumber: "Número de Cuotas",
  all: "Todo",
  allRightsReserved: "Todos los derechos reservados",
  amount: "Monto",
  amountToPay: "Monto a pagar",
  april: "Abril",
  areYouSureSendInstallmentReminder: "¿Estás seguro de enviar un recordatorio de pago?",
  august: "Agosto",
  authenticationFailed: "No se ha podido iniciar sesión",
  authenticationFailedMessage: "Verifique el correo electrónico y la contraseña",
  back: "Atrás",
  bank: "Banco",
  "bank-transfer": "Transferencia bancaria",
  bankAccounts: "Cuentas bancarias",
  birthdate: "Cumpleaños",
  boughtItem: "Artículo comprado",
  cancel: "Cancelar",
  campaignNumber: "N° de campaña",
  cardHolderFirstName: "Nombre del titular",
  cardHolderLastName: "Apellido del titular",
  cardNumber: "Número de tarjeta",
  cardType: "Tipo de tarjeta",
  category: "Categoría",
  certificate: "Certificado",
  certificates: "Certificados",
  certificateUnmsm: "Certificado físico - UNMSM",
  certificateCost: "Costo del certificado",
  certificatesDueDate: "Fecha límite para certificados",
  checkYourInbox: "Revisa tu correo",
  chooseAFile: "Seleccione un archivo",
  chooseCertificate: "Elegir certificado",
  chooseMultipleCertificates: "Recuerda que puedes escoger múltiples certificados si así lo deseas.",
  chooseYourGift: "Escoje tu regalo",
  clearFilters: "Limpiar filtros",
  code: "Código",
  company: "Empresa",
  complaintsLog: "Libro de reclamaciones",
  configuration: "Configuración",
  confirmPurchase: "Confirmar compra",
  continue: "Continuar",
  cookiesPolicy: "Política de cookies",
  couldNotSave: "No se pudo guardar",
  courseDiploma: "Curso, Programa o Diplomado",
  date: "Fecha",
  coursesAndDiplomas: "Courses and diplomas",
  courses: "Cursos",
  course: "Curso",
  courseDetails: "Detalles de {0}",
  courseStartDate: "Fecha inicio del curso",
  courseEndDate: "Fecha fin del curso",
  coursesCache: "Datos temporales de cursos",
  coursesCacheIsClean: "Datos temporales de cursos han sido limpiados",
  customer: "Cliente",
  dataSentSuccessfully: "Datos enviados con éxito",
  date: "Fecha",
  dateAndTime: "Fecha y hora",
  dateOfBirth: "Fecha de nacimiento",
  december: "Diciembre",
  delete: "Eliminar",
  deleteConfirmation: "¿Desea eliminar el registro seleccionado? Tenga en cuenta que esta acción no se puede deshacer",
  departament: "Departamento",
  district: "Distrito",
  digitalCertificateEnpp: "Certificado digital - ENPP",
  digitalCertificatePragmma: "Certificado digital",
  dniNeedsAtLeastEightsDigits: "El dni debe tener 8 digitos",
  download: "Descargar",
  document: 'Documento',
  documentNumber: 'Número de documento',
  downloadReport: "Descargar informe",
  dueDate: "Fecha de vencimiento",
  editPublicPrice: "Editar precio al público",
  editStatus: "Editar estado",
  edit: "Editar",
  billing: 'Facturación',
  email: "Correo electrónico",
  emailIsInvalid: "El correo is inválido",
  emailNotificationSent: "La notificación por email ha sido enviada",
  english: "Inglés",
  enppTermsConditionsPolicy: "Política de Términos y condiciones ENPP",
  enrollmentData: "Datos del matriculado",
  enrollments: "Matrículas",
  enrollmentsDiscountLimit: "Límite de matrículas con descuento",
  enter: "Ingresar",
  expirationDate: "Fecha de expiración",
  faq: "Preguntas frecuentes",
  february: "Febrero",
  fileFormat: "Formato del archivo",
  fileIsNotAllowed: "El archivo no esta permitido",
  fileSizeIsBiggerThanLimit: "El archivo supera el límite",
  filter: "Filtrar",
  filterByStatus: "Filtrar por estado",
  firstName: "Nombres",
  formCompletionMessage: "Gracias por completar el formulario",
  free: "Gratis",
  giftCourse: "Curso regalo",
  goToHome: "Ir al home",
  howDidYouHearAboutUs: "¿Como te enteraste de nosotros?",
  howDoIPay: "¿Cómo lo pago?",
  installment: "Cuota",
  installments: "Número de cuotas",
  inInstallments: "En {0} cuotas",
  installmentToPay: "Cuota por pagar",
  invoiceImage: "Imagen del comprobante",
  invoiceDate: "Fecha del comprobante",
  invoiceOf: "Voucher de <br/> {0}",
  issue: "Emitir",
  issueInvoice: "Emitir comprobante",
  issueDate: "Fecha de emisión",
  ipappgTermsConditionsPolicy: "Política de Términos y condiciones IPAPPG",
  january: "Enero",
  july: "Julio",
  june: "Junio",
  lastName: "Apellidos",
  lastSaved: "Último guardado",
  locationData: "Datos de procedencia",
  login: "Iniciar sesión",
  march: "Marzo",
  maximumSize: "Tamaño máximo",
  may: "Mayo",
  mobile: "Celular",
  mobileNeedsAtLeatNineDigits: "El celular debe tener al menos 9 digitos",
  mode: "Modalidad",
  montoPago: "Monto",
  name: "Nombre",
  new: "Nuevo",
  next: "Siguiente",
  november: "Noviembre",
  no: "No",
  october: "Octubre",
  ok: "Entendido",
  oneHundredSecurePayment: "Pago 100% seguro",
  onlyJPGisAllowed: "Solo esta permitido el formato JPG",
  operationNumber: "Nro. Operacion",
  others: "Otros",
  otherPayments: "Otros pagos",
  pageDoesNotExists: "Esta página no existe",
  pageDoesNotExistsMessage: "Asegúrese de que la url de la página sea la correcta. Te invitamos a intentar nuevamente o a visitar nuestros otros cursos y diplomados.",
  pagoEfectivoDescription: "Realiza una transferencia bancaria mediante tu banca por internet o banca móvil o realiza un depósito en efectivo acercándote a cualquier agencia, agente o bodega a pagar un servicio a nombre de la empresa recaudadora <strong>PAGO EFECTIVO</strong>.",
  password: "Contraseña",
  pay: "Pagar ahora",
  payInInstallments: "Pagar en cuotas",
  paymentInInstallments: "Pago en cuotas",
  payItBefore: "Págalo antes del {0}",
  payWithCard: "Pagar con tarjeta",
  payWithoutCard: "Pagar sin tarjeta",
  payed: "Pagado",
  paymentCard: "Tarjeta con la que se pagó",
  paymentCodeCIP: "Código de pago (CIP)",
  paymentData: "Datos de pago",
  paymentDate: "Fecha de pago",
  paymentDates: "Fechas de pago",
  paymentInformation: "Información para tu pago",
  paymentMethod: "Metodo de pago",
  paymentWithInstallments: "Pago en cuotas",
  pending: "Pendiente",
  personalData: "Datos personales",
  preSale: "Pre-venta",
  preSalePrice: "Precio pre-venta",
  prices: "Precios",
  privacyPolicy: "Políticas de privacidad",
  programCost: "Costo del programa",
  programs: "Programas",
  province: "Provincia",
  publicPrice: "Precio al público",
  purchaseNumber: "Nro. de pedido",
  recommendation: "Recomendación",
  registrationDate: "Fecha de registro",
  reminderWillBeSentToStudent: "Se enviará un correo de recordatorio a: {0}",
  reportMonth: "Mes del reporte",
  return: "Volver atrás",
  sale: "Venta",
  salesManager: "Carlos Alberto Mendoza Pinillos - Jefe de Ventas",
  salePrice: "Precio venta",
  savedSuccessfully: "Guardado exitosamente",
  savedButFileNotUploaded:
    "Se ha guardado correctament el registro pero la imagen no se ha podido subir",
  saveChanges: "Guardar cambios",
  saved: "Guardado",
  search: "Buscar",
  searchByCourseName: "Buscar por nombre del curso",
  searchByStudentNameEmail: "Buscar por nombre del estudiante o email",
  seeDetails: "Ver detalles",
  sendPaymentReminder: "Enviar recordatorio de pago",
  selectAnOption: "Seleccione una opción",
  selectOptions: "Seleccione las opciones",
  selected: "Seleccionados",
  september: "Septiembre",
  service: "Servicio",
  showingResults: "Mostrando {0} resultados",
  show: "Mostrar",
  showInPreenrollment: "Mostrar en pre-matrícula",
  spanish: "Español",
  status: "Estado",
  student: "Estudiante",
  studentData: "Datos del estudiante",
  studentDetails: "Detalles del estudiante",
  students: "Estudiantes",
  submitData: "Enviar datos",
  submitReminder: "Enviar recordatorio",
  successfulPayment: "Pago realizado con exito",
  successfulPaymentMessage:
    "Te hemos enviado un correo a tu bandeja (puede que esté en tu bandeja de spam) con las credenciales para que accedas al aula virtual.",
  successfulPaymentMessageIpappg:
    "Te hemos enviado un correo a tu bandeja (puede que esté en tu bandeja de spam) con la confirmación de la matrícula.",
  termsConditions: "Términos y condiciones",
  thisValueWillBeUsedInYourCertficate: "Este dato se usará en tu certificado",
  time: "Hora",
  totalCost: "Costo total",
  transfer: "Transferencia",
  tryAgain: "Intentar nuevamente",
  typeHere: "Escriba aquí",
  type: "Tipo",
  voidInvoice: "Anular",
  void: "Anular",
  voidConfirmation: '¿Está seguro que desea anular el comprobante?',
  weSentYouEmailOrderDetails:
    "Te acabamos de enviar un correo con los detalles del pedido",
  whatsappInvoiceMessage:
    "Envíanos una captura o foto de la operación a nuestro Whatsapp:",
  whatsappMessage: "Hola, adjunto la captura/foto de mi pago.",
  withDiscount: "Con descuento",
  yes: "Sí",
  yapeDescription:
    `Desde tu app Yape, escanea el QR y realiza el pago. Envíanos una captura de la operación con los siguientes datos a nuestro correo: 
    <a href="mailto:__EMAIL__">__EMAIL__</a><br><br>
- Nombre completo<br>
- Número de DNI<br>
- Número de celular<br>
- Correo electrónico del alumno<br>
- Nombre del diplomado`,

transferDescription:
`Envíanos una captura de la operación con los siguientes datos a nuestro correo: 
<a href="mailto:__EMAIL__">__EMAIL__</a><br><br>
- Nombre completo<br>
- Número de DNI<br>
- Número de celular<br>
- Correo electrónico del alumno<br>
- Nombre del diplomado`,
  
  youAreAboutToChangeStatus:
    'Esta a punto de cambiar el estado de <strong>"{0}"</strong> en el sistema.',
  youAreAboutToFinishPurchaseIn:
    "¡Estás a punto de finalizar tu compra en {0}!",
  youAreAboutToChangeCoursePrice:
    "Estás por cambiar el precio al público del programa {0} en el sistema",
  youAreOneStepToFinishPurchase: "¡Estás a un paso de finalizar la compra!",
};

export default es;
