import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../../store/appInfoSlicer";
import { initializeTranslator } from "../../../../helpers/language.helper";
import { Invoice } from "../../../../helpers/invoice.helper";
import { downloadInvoice } from "../../Enrollments/helper";
import SelectMenu from "../../../core/SelectMenu/SelectMenu";
import RoundButton from "../../../core/RoundButton/RoundButton";
import Spinner from "../../../core/Spinner/Spinner";
import PropTypes from "prop-types";
import {useParams} from "react-router-dom";
import moment from "moment";

import { useRecoilState } from 'recoil';
import { toastValuesState } from '../../../../store/recoil/recoilState';
import { notificationIcons } from '../../../core/ToastNotification/ToastNotification';


/**
 * Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const InvoiceIssuing = ({ enrollment, closeCallback }) => {

  const { company } = useParams();

  // get values from Redux state
  const language = useSelector(selectLanguage);

  // initialize translator with current language
  const t = initializeTranslator(language);

  // ---------------------------------------
  // set state values

  const today = moment().format('YYYY-MM-DD');
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceType, setInvoiceType] = useState('boleta');
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [customerAddress, setCustomerAddress] = useState('');
  const [documentType, setDocumentType] = useState('dni');
  const [documentNumber, setDocumentNumber] = useState('');
  const [invoiceConfiguration, setInvoiceConfiguration] = useState(null);
  const [selectedDate, setSelectedDate] = useState(today);

  // Notifications state

  const [toastValues, setToastValues] = useRecoilState(toastValuesState);

  // Lifecycle hooks
  // ---------------------------------------

  
  useEffect(() => {

    (async () =>  {
        await loadInvoicesConfiguration()
    })();

  }, []);

  useEffect(() => {

    if (invoiceType && invoiceConfiguration) {
        updateInvoiceNumber()
    }
   

  }, [invoiceType, invoiceConfiguration]);

  // ---------------------------------------
  // Functions

  const loadInvoicesConfiguration = async () => {
    let _invoiceConfig = await Invoice.loadConfiguration(company);
    setInvoiceConfiguration(_invoiceConfig);  
  }

  const updateInvoiceNumber = () => {

    if (invoiceConfiguration && invoiceConfiguration.nextNumber) {
        setInvoiceNumber(invoiceConfiguration.nextNumber[invoiceType])
    }    
  }

  const handleDateChange = (event) => { 
    setSelectedDate(event.target.value);
    
  };

  /**
   * Submit request to issue invoice
   * @returns {Promise<void>}
   */
  const issueInvoice = async () => {

   setIsLoading(true)

    const customer = {
        name: customerName,
        documentType: documentType,
        documentNumber: documentNumber,
        address: customerAddress
    }

    const invoicePayload = {
        type: invoiceType,
        number: invoiceNumber,
        date: selectedDate
    }

    let { success, message, createdInvoice } = await Invoice.issue(
        company, invoicePayload, enrollment.enrollment_id, customer
    );

    if (success) {

        // Reset form

        setInvoiceNumber('')
        setCustomerName('')
        setDocumentNumber('')
        setCustomerAddress('')

        await downloadInvoice(company, createdInvoice.id);
        setIsLoading(false)
        
        await loadInvoicesConfiguration()
        updateInvoiceNumber()

        closeCallback()

    } else {

        setToastValues({
            icon: notificationIcons.error,
            title: 'No se pudo generar el comprobante',
            message: 'Verifique la numeración y datos del cliente',
            isVisible: true,
            closeCallback: () => { setToastValues({...toastValues, isVisible: false}) }
        })
    }

    setIsLoading(false)
  }

  // ---------------------------------------
  // return component

  return (
    <div className="row">

      {/* Loading spinner */}

      {isLoading ? <Spinner /> : null}

        <form className="default-form col-12 mb-1">
        
            <span className="control-label">{t('date')}</span>
            <div className="input-wrapper mt-1">
                <input
                    type="date"
                    name="date"
                    style={{width: '95%'}}
                    placeholder={t('issueDate')}
                    value={selectedDate} 
                    onChange={handleDateChange}
                />
            </div>

            {/* Invoice type selector */}
            
            <span className="control-label">{t('type')}</span>
            <SelectMenu
                description={t("selectAnOption")}
                multiselection={false}
                selectedItem={{ value: 'boleta', text: 'Boleta de venta' }}
                optionSelectedCallback={v => setInvoiceType(v)}
                options={[
                        { value: 'boleta', text: 'Boleta de venta' },
                        { value: 'factura', text: 'Factura' },
                    ]}
            />
            <div className="input-wrapper mt-1">
                <input
                    type="text"
                    name="invoice-number"
                    value={invoiceNumber}
                    placeholder={'Número'}
                    onChange={e => setInvoiceNumber(e.target.value)}
                />
            </div>
        </form>

        <form className="default-form col-12 mb-1 mt-3">

            {/* Document type selector */}

            <span className="control-label">{t("document")}</span>
            <SelectMenu
                description={t("selectAnOption")}
                selectedItem={{value: 'dni', text: 'DNI'}}
                optionSelectedCallback={v => setDocumentType(v)}
                options={[
                    {value: 'dni', text: 'DNI'},
                    {value: 'ruc', text: 'RUC'},
                    {value: 'extranjeria', text: 'Carnet de extranjería'}
                ]}
            />


            <div className="input-wrapper mt-1">
                <input
                    type="text"
                    name="document-number"
                    value={documentNumber}
                    placeholder={t('documentNumber')}
                    onChange={e => setDocumentNumber(e.target.value)}
                />
            </div>

            <span className="control-label mt-3">{t("customer")}</span>
            <div className="input-wrapper">
                <input
                    type="text"
                    value={customerName}
                    placeholder={'Nombre o razón social'}
                    onChange={e => setCustomerName(e.target.value)}
                />
            </div>

            <div className="input-wrapper mt-3">
                <input
                    type="text"
                    value={customerAddress}
                    placeholder={'Dirección'}
                    onChange={e => setCustomerAddress(e.target.value)}
                />
            </div>

        </form>

        <div className="col-12 mt-5">

            {/* Button to issue a new invoice */}

            <RoundButton
                onClick={issueInvoice}
                isDisabled={
                    !invoiceType ||
                    !documentType ||
                    !documentNumber ||
                    !customerName ||
                    !customerAddress ||
                    isLoading
                }
                width={"100%"}
                height={"3rem"}
                outline={false}
                content={t("issue")}
            />
        </div>
    </div>
  );
};

InvoiceIssuing.propTypes = {
    enrollment: PropTypes.shape({
        enrollment_id: PropTypes.number.isRequired
    }).isRequired,
};

export default InvoiceIssuing;
